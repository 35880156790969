// RoutesWithTransitions.js

import { Routes, Route, useLocation } from "react-router-dom";
import LoginScreen from "./pages/LoginScreen";
import SignUp from "./pages/SignUp";
import "./index.css";
import ArtistHomepage from "./pages/ArtistHomepage";
import CreateNewPiece from "./pages/CreateNewPiece";
import PieceInformation from "./pages/PieceInformation";
import Comments from "./pages/CommentsPage";
import Purchase from "./pages/PurchasePage";
import ApplePay from "./components/ApplePay";
import Registration from "./pages/Registration";
import Breadcrumbs from "./components/Breadcrumbs";
import LinkConfirmation from "./pages/LinkConfirmation";
import ScannerPage from "./pages/Scanner";
import ArtistBioPage from "./pages/ArtistBioPage";
import PieceInformationWithId from "./pages/PieceInformationWithId";
import AdminPanel from "./pages/AdminPanel";
import ProtectedRoute from "./components/ProtectedRoute";
import { AnimatePresence, motion } from "framer-motion";
import DeleteConfirmation from "./pages/DeleteConfirmation";
import Onboarding from "./pages/Onboarding";
import OnboardingDemo from "./pages/OnboardingDemo";
import LoginScreenDemo from "./components/LoginScreenDemo";
import RegistrationDemo from "./pages/RegistrationDemo";
import CheckoutForm from "./components/CheckoutForm";
import StripePurchaseComplete from "./pages/StripePurchaseComplete";
import OnboardingPage from "./pages/AccountOnboardingEmbedded";
import CompletedOnboardingPage from "./pages/CompletedOnboardingPage";
import PublicFacingArtistBio from "./pages/PublicFacingArtistBio";
import StripePurchaseCancel from "./pages/StripePurchaseCancel";
import PurchaseConfirmation from "./pages/PurchaseConfirmation";
import Settings from "./pages/Settings";

const RoutesWithTransitions = () => {
  const location = useLocation();

  const variants = {
    out: {
      opacity: 0,
      x: "-100vw",
      transition: { type: "tween", ease: "easeInOut" },
    },
    in: {
      opacity: 1,
      x: 0,
      transition: { type: "tween", ease: "easeInOut", duration: 0.5 },
    },
  };
  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          className="next"
          variants={variants}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Routes location={location}>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <ArtistHomepage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stripe-purchase-complete/:linkId"
              element={<StripePurchaseComplete />}
            />
            <Route
              path="/stripe-purchase-cancel/:linkId"
              element={<StripePurchaseCancel />}
            />
            <Route
              path="/home/pay"
              element={
                <ProtectedRoute>
                  <CheckoutForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stripe-onboarding"
              element={
                <ProtectedRoute>
                  <OnboardingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/embedded-onboard-complete/:accountId"
              element={
                <ProtectedRoute>
                  <CompletedOnboardingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/newPiece"
              element={
                <ProtectedRoute>
                  <CreateNewPiece />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/piece"
              element={
                <ProtectedRoute>
                  <PieceInformation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route path="/artPiece/:linkId/comments" element={<Comments />} />
            <Route
              path="/artPiece/:linkId/purchase"
              element={<PurchaseConfirmation />}
            />
            <Route path="/purchase" element={<Purchase />} />
            <Route
              path="/apple-pay"
              element={
                <ProtectedRoute>
                  <ApplePay />
                </ProtectedRoute>
              }
            />

            <Route
              path="/link-confirmation"
              element={
                <ProtectedRoute>
                  <LinkConfirmation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/scanner"
              element={
                <ProtectedRoute>
                  <ScannerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/artist-bio-page"
              element={
                <ProtectedRoute>
                  <ArtistBioPage isEditable={false} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/artist/:artistUid"
              element={<PublicFacingArtistBio />}
            />
            <Route
              path="/artist-setup-page"
              element={
                <ProtectedRoute>
                  <ArtistBioPage isEditable={true} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/piece/:pieceId"
              element={
                <ProtectedRoute>
                  <PieceInformationWithId
                    isLinked={false}
                    isPublicFacing={false}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/artpiece/:linkId"
              element={
                <PieceInformationWithId isLinked={true} isPublicFacing={true} />
              }
            />
            <Route
              path="/piece/:pieceId"
              element={
                <PieceInformationWithId isLinked={true} isPublicFacing={true} />
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onboarding-demo"
              element={
                <ProtectedRoute>
                  <OnboardingDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/login-demo"
              element={
                <ProtectedRoute>
                  <LoginScreenDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register-demo"
              element={
                <ProtectedRoute>
                  <RegistrationDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/delete-success"
              element={
                <ProtectedRoute>
                  <DeleteConfirmation />
                </ProtectedRoute>
              }
            />
          </Routes>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default RoutesWithTransitions;
