import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import Lottie from "react-lottie";
import { load } from "./loading";

interface StripeStatusProps {
  isLoaded: boolean;
  isStripeFullyOnboarded: boolean;
  detailsSubmitted: boolean;
}

const StripeStatus: React.FC<StripeStatusProps> = ({
  isLoaded,
  isStripeFullyOnboarded,
  detailsSubmitted,
}) => {
  const navigate = useNavigate();

  const renderStatusIcon = () => {
    if (isStripeFullyOnboarded && isLoaded) {
      return (
        <CheckCircleOutlined
          style={{ color: "#66FF00", marginRight: "10px" }}
        />
      );
    } else if (detailsSubmitted) {
      return (
        <MinusCircleOutlined style={{ color: "yellow", marginRight: "10px" }} />
      );
    } else {
      return (
        <ExclamationCircleOutlined
          style={{ color: "#EE4B2B", marginRight: "10px" }}
        />
      );
    }
  };

  const renderStatusMessage = () => {
    if (isStripeFullyOnboarded && isLoaded) {
      return "Stripe account connected";
    } else if (detailsSubmitted) {
      return "Please resume account onboarding to start selling. Click here to continue.";
    } else {
      return "Complete your account information to start selling. Click here to start.";
    }
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <Lottie
          style={{ margin: "0" }}
          options={{
            loop: true,
            autoplay: true,
            animationData: load,
          }}
          height={50}
          width={50}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", height: "50px" }}
        onClick={() =>
          !isStripeFullyOnboarded && navigate("/stripe-onboarding")
        }
      >
        {renderStatusIcon()}
        <h4 style={{ margin: 0 }}>{renderStatusMessage()}</h4>
      </div>
    </div>
  );
};

export default StripeStatus;
