import dmnLogo from "../assets/DMNICON.png"; // adjust the path as needed
import "./DMNLogoAligned.css";

const DMNLogoAligned = () => {
  return (
    <div className="dmn-logo-container">
      <img src={dmnLogo} alt="DMN Logo" className="dmn-logo" />
    </div>
  );
};

export default DMNLogoAligned;
