import { QrScanner } from "@yudiel/react-qr-scanner";
import { useCallback, useState } from "react";
import { debounce, getUIDFromResult } from "../lib/utils";

const Scanner = ({ setResult, result }: any) => {
  const [errorMessage, setErrorMessage] = useState("");

  const fetchLinkedImage = async (link: string) => {
    console.log(link);
    const uidForLink = getUIDFromResult(link);
    console.log("uidForLink: ", uidForLink);
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/link/${uidForLink}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status === 404) {
        setResult(link);
        setErrorMessage("");
        return;
      }
      const result = await response.json();

      if (result.linkId) {
        setErrorMessage(
          "This QR code is already linked to a piece. Please scan another QR code to link instead!"
        );
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const debouncedFetchLinkedImage = useCallback(
    debounce(fetchLinkedImage, 300),
    []
  );

  const handleResult = async (result: string) => {
    debouncedFetchLinkedImage(result);
  };
  return (
    <>
      <QrScanner
        // I am only commenting these styles out because it looks better on mobile.
        // videoStyle={{ maxHeight: "100px", maxWidth: "100px" }}
        // containerStyle={{ maxHeight: "100px", maxWidth: "100px" }}
        onDecode={(result) => {
          handleResult(result);
        }}
        onError={(error) => console.log(error)}
      />
      {/* error message */}
      <p style={{ color: "white" }}>{errorMessage}</p>
    </>
  );
};

export default Scanner;
