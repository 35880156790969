import React, { useEffect, useState } from "react";
import art from "../assets/art.webp";
import { PaymentForm } from "react-square-web-payments-sdk";
import ApplePay from "../components/ApplePay";
import FsLightbox from "fslightbox-react";
import ResponsiveImage from "../components/ResponsiveImage";
import { useNavigate, useParams } from "react-router-dom";
import { Tag } from "antd";
import { formatPriceWithCommas, pieceTypes } from "../lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

const Purchase: React.FC = () => {
  const navigate = useNavigate();
  const { pieceId, linkId } = useParams();
  const [artPieceInformation, setArtPieceInformation] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [artistInformation, setArtistInformation] = useState<any>(null);
  const [toggler, setToggler] = useState(false);

  const fetchLinkedImage = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/link/${linkId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.ok) {
        setIsLoading(false);
        setArtPieceInformation(null);
      }
      const result = await response.json();
      setArtPieceInformation(result);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const fetchArtistInformation = async () => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/artist/${artPieceInformation.artistUid}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch artist information");
      }
      const artist = await response.json();
      setArtistInformation(artist);
    } catch (error) {
      console.error("Error fetching artist information: ", error);
    }
  };

  useEffect(() => {
    if (linkId) {
      fetchLinkedImage();
      // fetchArtistInformation();
      //   setTaggedEntity(linkId?.substring(0, 6).toUpperCase() || "");
    }
  }, [linkId]);

  useEffect(() => {
    if (artPieceInformation?.artistUid) {
      fetchArtistInformation();
    }
  }, [artPieceInformation?.artistUid]);

  const handleBuy = async () => {
    const { price } = artPieceInformation;

    try {
      const response = await fetch(
        "https://sparkplug-api.onrender.com/payment/create-charge",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: price * 100, // Convert to cents
            artistStripeAccountId: artistInformation.stripeAccount.id,
            linkId: linkId,
            piece: `${artPieceInformation.name} by ${artistInformation.displayName}`,
          }),
        }
      );

      const data = await response.json();
      if (response.ok && data.url) {
        if (data.url) {
          return window.location.assign(data.url);
        }
      } else {
        console.error("Error creating charge:", data.error);
      }
    } catch (error) {
      console.error("Error creating charge:", error);
    }
  };

  return (
    <div
      style={{
        // backgroundColor: "black",
        color: "white",
        padding: "20px",
        margin: "10px", // Added margin around the entire component
      }}
    >
      <FontAwesomeIcon
        style={{
          marginRight: "auto",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
        size="xl"
        icon={faAngleLeft}
        color="white"
        onClick={() => {
          navigate("/artpiece/" + linkId);
        }}
      />
      {/* Container to center the image */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ResponsiveImage
          src={artPieceInformation?.photo}
          alt="example"
          // @ts-ignore
          onToggle={() => setToggler(!toggler)}
        />
        <FsLightbox toggler={toggler} sources={[artPieceInformation?.photo]} />
      </div>

      {/* Text and Icon (left-aligned) */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between",
          width: "350px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            justifyContent: "center",
            width: "350px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {" "}
            <h2 id="title" style={{ margin: 0 }}>
              {artPieceInformation?.name}
            </h2>
            <h4
              id="artist"
              style={{ margin: 0, paddingLeft: 10, marginBottom: "10px" }}
            >
              by {artistInformation?.displayName}
            </h4>
            <h2 id="title">
              {artPieceInformation?.type.map((type: string, index: number) => {
                return <Tag color="#7800FF">{type}</Tag>;
              })}
            </h2>
            <h4 id="size" style={{ margin: 0 }}>
              {artPieceInformation?.height &&
                `${artPieceInformation?.height || ""} x ${
                  artPieceInformation?.width || ""
                } ${artPieceInformation?.units || ""}`}
            </h4>
            <h2 id="title" style={{ margin: 0 }}>
              {artPieceInformation?.price
                ? "Price: $" + formatPriceWithCommas(artPieceInformation.price)
                : "Not For Sale"}
            </h2>
            <h2 id="title" style={{ margin: 0 }}>
              {"Fee: $2"}
            </h2>
            <h2 id="title" style={{ margin: 0 }}>
              {`Total Price: ${
                "$" + formatPriceWithCommas(artPieceInformation?.price + 2)
              }`}
            </h2>
            <button
              style={{
                marginTop: "50px",
                fontWeight: "bold",
                width: "300px",
                backgroundColor: "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={handleBuy}
            >
              Confirm Purchase
              <FontAwesomeIcon
                style={{ marginLeft: "5px" }}
                icon={faArrowUpRightFromSquare}
              />
            </button>
          </div>
        </div>
      </div>

      <br />

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
        }}
      ></div>
    </div>
  );
};

export default Purchase;
