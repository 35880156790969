import React, { useEffect, useState } from "react";
import {
  StripeConnectInstance,
  loadConnectAndInitialize,
} from "@stripe/connect-js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createExpressAccount } from "../lib/api";
import AccountOnboardingUI from "../components/AccountOnboardingUI";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const fetchClientSecret = async (accountId: string) => {
  const response = await fetch(
    "https://sparkplug-api.onrender.com/user/stripe/create-account-session",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accountId }),
    }
  );
  const { client_secret: clientSecret } = await response.json();
  return clientSecret;
};

const getConnectedAccountDetails = async (accountId: string) => {
  const response = await fetch(
    `https://sparkplug-api.onrender.com/user/stripe/connected-account/${accountId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};

const OnboardingPage: React.FC = () => {
  const navigate = useNavigate();
  const [accountSessionUrl, setAccountSessionUrl] = useState<string>("");
  const [connectInstance, setConnectInstance] =
    useState<StripeConnectInstance | null>(null);
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stripeAccountId, setStripeAccountId] = useState<string>("");

  const getUserByUid = async (uid: string) => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/${uid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const userResponse = await response.json();
      setUser(userResponse);
      return userResponse;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const createStripeAccount = async (userObj: any) => {
      if (!userObj) return; // Wait for the user to be set

      // Create a new account if the user doesn't have one
      const response = await createExpressAccount({
        email: userObj.email,
        userId: userObj.uid,
      });
      const accountId = response.accountId;
      console.log("setting stripe account id: ", accountId);
      setStripeAccountId(accountId);
    };

    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const uid = authUser.uid;
        const userData = await getUserByUid(uid);
        console.log("userData: ", userData);
        if (userData.stripeAccount.id === "") {
          await createStripeAccount(userData);
          const updatedUserData = await getUserByUid(uid);
          setUser(updatedUserData);
        } else {
          setUser(userData);
          setStripeAccountId(userData.stripeAccount.id);
        }

        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const setupOnboarding = async () => {
      if (!stripeAccountId) return; // Wait for the stripe account ID to be set
      const accountDetails = await getConnectedAccountDetails(stripeAccountId);
      if (accountDetails.payouts_enabled) {
        navigate(`/embedded-onboard-complete/${stripeAccountId}`);
        return;
      }

      const clientSecret = await fetchClientSecret(stripeAccountId);
      const instance = loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_API_KEY || "",
        fetchClientSecret: async () => clientSecret,
        appearance: {
          variables: {
            colorPrimary: "#7800ff",
            buttonPrimaryColorBackground: "#7800ff",
            buttonPrimaryColorBorder: "#7800ff",
            actionPrimaryColorText: "#7800ff",
            formHighlightColorBorder: "#7800ff",
            formAccentColor: "#7800ff",
          },
        },
      });

      setAccountSessionUrl(clientSecret);
      setConnectInstance(instance);
    };
    setupOnboarding();
  }, [user]);

  console.log("stripeAccountId: ", stripeAccountId);
  console.log("connectInstance: ", connectInstance);
  console.log("user.stripeAccount?.status: ", user?.stripeAccount?.status);

  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        minWidth: "100vw",
        margin: 0,
        padding: 0,
      }}
    >
      {connectInstance && user && user.stripeAccount?.status === false ? (
        <AccountOnboardingUI
          connectInstance={connectInstance}
          accountId={stripeAccountId}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Ensure it takes the full viewport height
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 100, color: "#7800FF" }}
                spin
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default OnboardingPage;
