import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const inputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };
  return (
    <div
      style={{
        // backgroundColor: "#1c1c1e", // Dark background color
        padding: "20px",
        height: "100vh", // Full height
        color: "white",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <FontAwesomeIcon
        style={{ marginRight: "auto", marginLeft: "10px", marginTop: "10px" }}
        size="xl"
        icon={faAngleLeft}
        color="white"
        onClick={() => navigate("/home")}
      />
      <h1 style={{ color: "white", marginBottom: "30px" }}>Settings</h1>

      <div style={{ marginBottom: "20px" }}>
        <input type="text" placeholder="Artist Name" style={inputStyle} />
        <input type="email" placeholder="Email" style={inputStyle} />
        <input type="tel" placeholder="Phone Number" style={inputStyle} />
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h2 style={{ marginBottom: "10px", fontSize: "18px" }}>
          Notification Preferences
        </h2>

        {[
          { label: "Sales", name: "sales" },
          { label: "Comments", name: "comments" },
          { label: "Announcements", name: "announcements" },
        ].map((item) => (
          <div
            key={item.name}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label style={{ fontSize: "16px" }}>{item.label}</label>
            <div>
              <input
                type="checkbox"
                id={`${item.name}-text`}
                style={{ marginRight: "10px" }}
              />
              <label
                htmlFor={`${item.name}-text`}
                style={{ marginRight: "20px" }}
              >
                Text
              </label>
              <input type="checkbox" id={`${item.name}-email`} />
              <label htmlFor={`${item.name}-email`}>Email</label>
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "120px",
        }}
      >
        <button
          onClick={() => console.log("hi")}
          style={{
            width: "125px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Settings;
