import React, { useState } from "react";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

interface AccountOnboardingUIProps {
  connectInstance: any;
  accountId: string;
}

const AccountOnboardingUI = ({
  connectInstance,
  accountId,
}: AccountOnboardingUIProps) => {
  const navigate = useNavigate();

  const updateAccount = async (accountId: string) => {
    const response = await fetch(
      `https://sparkplug-api.onrender.com/user/complete-onboarding/${accountId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ onboardingComplete: true }),
      }
    );
    const data = await response.json();
    return data;
  };

  const getConnectedAccountDetails = async (accountId: string) => {
    const response = await fetch(
      `https://sparkplug-api.onrender.com/user/stripe/connected-account/${accountId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  };

  return (
    <div id="onboarding" style={{ padding: "25px" }}>
      <FontAwesomeIcon
        // left align
        style={{ marginRight: "auto", marginLeft: "5px" }}
        size="xl"
        icon={faAngleLeft}
        color="black"
        onClick={() => navigate(`/home`)}
      />
      <ConnectComponentsProvider connectInstance={connectInstance}>
        <ConnectAccountOnboarding
          onExit={async () => {
            console.log("The account has exited onboarding");
            // If the user completes onboarding an exits the flow, we can update their account
            if (accountId) {
              const accountDetails = await getConnectedAccountDetails(
                accountId
              );
              //@ts-ignore
              if (accountDetails && accountDetails.payouts_enabled) {
                await updateAccount(accountId);
              }
            }
            // navigate to the completed page but render it depending on if they're onboarded or not
            navigate(`/embedded-onboard-complete/${accountId}`);
          }}
          // Optional: make sure to follow our policy instructions above
          // fullTermsOfServiceUrl="{{URL}}"
          // recipientTermsOfServiceUrl="{{URL}}"
          // privacyPolicyUrl="{{URL}}"
          // skipTermsOfServiceCollection={false}
          collectionOptions={{
            fields: "currently_due",
            futureRequirements: "include",
          }}
        />
      </ConnectComponentsProvider>
    </div>
  );
};

export default AccountOnboardingUI;
