import { Badge } from "antd";
import React from "react";
import { listLatestLocationForCustomerFacingPiece } from "../lib/utils";

interface LocationInputProps {
  artPieceInformation: any;
  setArtPieceInformation: any;
  setIsLocationCleared: any;
  setLocationSearch: any;
  setHasSelectedLocation: any;
  locationSearch: any;
  onLocationSearchChange: any;
  suggestions: any;
  selectPlace: any;
  setSuggestions: any;
  onLocationClear: any;
  hasLocation: boolean;
  hasSelectedLocation: boolean;
}

const LocationInput: React.FC<LocationInputProps> = ({
  artPieceInformation,
  setArtPieceInformation,
  setIsLocationCleared,
  setLocationSearch,
  setHasSelectedLocation,
  locationSearch,
  onLocationSearchChange,
  suggestions,
  selectPlace,
  setSuggestions,
  onLocationClear,
  hasLocation,
  hasSelectedLocation,
}) => {
  // console.log("locationSearch: ", locationSearch);
  const commonInputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };
  // console.log("artPieceInformation.location: ", !!!artPieceInformation.location);
  // console.log(
  //   "artPieceInformation.location.name: ",
  //   artPieceInformation.location.name
  // );
  // console.log("locationSearch: ", locationSearch);
  console.log("artPieceInformation: ", artPieceInformation);
  // console.log(!artPieceInformation.location);
  // console.log(artPieceInformation.location.endDate);
  // console.log(artPieceInformation.location.name === "Artist's Collection");
  // console.log(!hasSelectedLocation);
  if (
    !artPieceInformation.location ||
    artPieceInformation.location.endDate ||
    artPieceInformation.location.name === "Artist's Collection" ||
    !hasSelectedLocation
  ) {
    // console.log("artPieceInformation: ", artPieceInformation);
    // console.log("locationSearch: ", locationSearch);
    return (
      <div>
        <input
          id="location"
          type="text"
          value={locationSearch.name}
          // value={locationSearch}
          onChange={onLocationSearchChange}
          style={commonInputStyle}
          placeholder="Search for places..."
          autoComplete="off"
          disabled={!hasLocation}
        />
        {suggestions.length > 0 && (
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
              marginTop: "5px",
              margin: 0,
              position: "absolute",
              top: "100%",
              left: 0,
              width: "100%",
              backgroundColor: "#1f2129",
              zIndex: 1000,
            }}
          >
            {suggestions.map((suggestion: any) => (
              <li
                key={
                  suggestion.geometry.location.lat +
                  suggestion.geometry.location.lng
                } // You can generate a unique key like this
                onClick={() => {
                  selectPlace(suggestion);
                  setSuggestions([]);
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor: "#333",
                  color: "white",
                  border: "1px solid #444",
                  borderRadius: "5px",
                  margin: "2px 0",
                }}
              >
                {suggestion.name}
                <br />
                <i style={{ fontSize: "14px" }}>
                  {suggestion.formatted_address}
                </i>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  return (
    <Badge
      count={"X"}
      // @ts-ignore
      // When I click this, this should clear the location and go to the search location
      // component. When I click on the search location component,
      // I should be able to select a new location.
      onClick={onLocationClear}
    >
      <ul style={{ listStyleType: "none", padding: 0, marginTop: "5px" }}>
        <li
          style={{
            cursor: "pointer",
            padding: "10px",
            backgroundColor: "#333",
            color: "white",
            border: "1px solid #444",
            borderRadius: "5px",
            margin: "2px 0",
          }}
        >
          {artPieceInformation.location?.name}
          <br />
          <i style={{ fontSize: "14px" }}>
            {artPieceInformation.location?.formatted_address}
          </i>
        </li>
      </ul>
    </Badge>
  );
};

export default LocationInput;
