import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Scanner from "./QRScanner";
import { getUIDFromResult } from "../lib/utils";

const ScannerPage = () => {
  // retrieve the piece information from the state in navigation
  const location = useLocation();
  const { artPieceInformation } = location.state;

  const navigate = useNavigate();
  const [result, setResult] = useState("");

  const linkPiece = async (uid: any, linkId: any) => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/link/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            linkId: linkId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to link the document");
      }

      const linkedDocument = await response.json();
      return linkedDocument;
    } catch (error) {
      console.error("Error linking document: ", error);
    }
  };

  useEffect(() => {
    if (result === "") {
      return;
    } else {
      const uidForLink = getUIDFromResult(result);
      if (uidForLink) {
        const pieceId = artPieceInformation._id;
        linkPiece(pieceId, uidForLink).then(() => {
          navigate("/link-confirmation", {
            state: { linkId: uidForLink, pieceId: pieceId },
          });
        });
      }
    }
  }, [result]);

  return (
    <div
      style={{
        backgroundColor: "#1f2129",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          margin: "20px",
        }}
      >
        <h1>Link QR Code</h1>
        <Scanner result={result} setResult={setResult} />
      </div>
    </div>
  );
};

export default ScannerPage;
