import {
  faAngleLeft,
  faCheck,
  faLink,
  faLocationDot,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllPiecesByArtist } from "../lib/api";
import {
  generateLatestLocationName,
  getTotalViews,
  groupByLocation,
} from "../lib/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Bounce, toast, ToastContainer } from "react-toastify";
import ViewIcon from "../components/ViewIcon";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import GroupedPiecesByLocation from "../components/GroupedPiecesByLocation";
import { Helmet } from "react-helmet";

const PublicFacingArtistBio = () => {
  const location = useLocation();
  const previousState = location.state;
  const { fromPiece } = previousState || {};
  const { artistUid } = useParams();
  const navigate = useNavigate();
  const topBarRef = useRef(null);
  const [artist, setArtist] = useState<any>({});
  const [pieces, setPieces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [userInformation, setUserInformation] = useState<any>([]);

  useEffect(() => {
    console.log(window.history.scrollRestoration);
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (topBarRef.current) {
      // @ts-ignore
      topBarRef.current.tabIndex = -1;
      // @ts-ignore
      topBarRef.current.focus();
    }
  }, []);

  const fetchArtistInformation = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/artist/${artistUid}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch artist information");
      }
      const artist = await response.json();
      return artist;
    } catch (error) {
      console.error("Error fetching artist information: ", error);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserInformation(user);
      }
    });
  }, []);

  useEffect(() => {
    fetchArtistInformation().then((artistInfo) => {
      setArtist(artistInfo);
    });
    if (artistUid) {
      getAllPiecesByArtist(artistUid).then((artPieces) => {
        setPieces(artPieces);
        setIsLoading(false);
      });
    }
  }, [artistUid]);

  const handlePiece = (piece: any) => {
    if (piece.linkId) {
      navigate(`/artpiece/${piece.linkId}`);
    } else {
      navigate(`/piece/${piece._id}`);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const notify = () => {
    // Show the checkmark
    setShowCheckmark(true);

    // Hide the checkmark after 3 seconds
    setTimeout(() => {
      setShowCheckmark(false);
    }, 3000);

    copyToClipboard(`https://darkmatter.network/artist/${artistUid}`);
    toast("Artist link copied to clipboard!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100vh", // Take up the full viewport height
        }}
      >
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 100, color: "#7800FF" }} spin />
          }
        />
      </div>
    );
  }
  const groupedPieces = groupByLocation(pieces);
  console.log(artist.profilePicture);
  return (
    <>
      <Helmet>
        <title>{`${artist.displayName} - ArtLink`}</title>
        <meta property="og:title" content={artist.displayName} />
        {/* <meta
          property="og:description"
          content={`${artist.displayName} on ArtLink`}
        />
        <meta property="og:image" content={artist.profilePicture} />
        <meta
          property="og:url"
          content={`https://darkmatter.network/artist/${artistUid}`}
        /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#1f2129",
          color: "white",
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "25px",
          paddingBottom: "50px",
        }}
      >
        <ToastContainer />
        <div
          ref={topBarRef}
          style={{
            outline: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <FontAwesomeIcon
            style={{ display: userInformation.uid || fromPiece ? "" : "none" }}
            size="xl"
            icon={faAngleLeft}
            color="white"
            onClick={() => {
              console.log("hi");
              navigate(-1);
            }}
          />
        </div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            margin: "20px",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "30px",
              fontWeight: 900,
              lineHeight: "41.86px",
              wordWrap: "break-word",
            }}
          >
            {artist.displayName}
            <FontAwesomeIcon
              onClick={() => notify()}
              icon={showCheckmark ? faCheck : faLink}
              color={"#7800FF"}
              size="sm"
              style={{ alignSelf: "center", marginLeft: "10px" }} // Centers the icon if it's not aligned with the text
            />
          </div>
          <div
            style={{
              color: "#7800FF",
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "22.32px",
              wordWrap: "break-word",
            }}
          >
            {artist.subheader}
          </div>

          <div
            style={{
              position: "relative",
              display: "inline-block",
              margin: "10px",
            }}
          >
            <Avatar shape="square" size={151} src={artist.profilePicture} />
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            <span
              style={{
                color: "white",
                fontSize: "18px",
                // fontFamily: "Inter",
                fontWeight: 900,
                textDecoration: "underline",
                lineHeight: "25.11px",
                wordWrap: "break-word",
              }}
            >
              Bio:
            </span>
            <br />
            <span
              style={{
                color: "white",
                fontSize: "18px",
                fontWeight: 900,
                lineHeight: "25.11px",
                wordWrap: "break-word",
              }}
            >
              {artist.bio}
            </span>
          </div>

          <div style={{ textAlign: "left" }}>
            <div
              style={{
                color: "white",
                fontSize: "30px",
                fontWeight: 900,
                lineHeight: "41.86px",
                wordWrap: "break-word",
                marginTop: "50px",
              }}
            >
              Pieces
            </div>
            <GroupedPiecesByLocation
              groupedPieces={groupedPieces}
              handlePiece={handlePiece}
              getTotalViews={getTotalViews}
            />
          </div>
        </div>

        {/* Bottom Buttons */}
        <div
          style={{
            display: "flex",
            position: "fixed",
            justifyContent: "space-evenly",
            left: 0,
            bottom: 0,
            width: "100%",
            backgroundColor: "#1f2129",
            color: "white",
            textAlign: "center",
            padding: "10px 0", // Adjust as needed
            zIndex: 1000, // Ensure it's above other content
          }}
        >
          {/* Edit Button */}
        </div>
      </div>
    </>
  );
};

export default PublicFacingArtistBio;
