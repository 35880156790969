import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch } from "react";
import { useNavigate } from "react-router-dom";

interface LinkModalProps {
  setIsModalVisible: Dispatch<React.SetStateAction<boolean>>;
  artPieceInformation: any;
  setArtPieceInformation?: any;
  setTaggedEntity: any;
  createTag: () => void;
}

const LinkModal = ({
  setIsModalVisible,
  artPieceInformation,
  setArtPieceInformation,
  setTaggedEntity,
  createTag,
}: LinkModalProps) => {
  const navigate = useNavigate();

  const unlinkArtPiece = async () => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/unlink/${artPieceInformation._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.ok) {
        console.error("Failed to unlink art piece");
        return;
      }
      const result = await response.json();
      console.log("result: ", result);
      setArtPieceInformation(result);
      setTaggedEntity("");
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error unlinking art piece: ", error);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(31, 33, 40, 0.95)",
        padding: "20px",
        borderRadius: "20px",
        border: "5px solid #7800FF",
        minWidth: "250px",
        minHeight: "250px",
        zIndex: 1001, // Above the button container
      }}
    >
      <div id="hi" style={{ marginTop: "50px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Stack buttons vertically
            alignItems: "center",
            justifyContent: "space-around", // Evenly space the buttons
            height: "100%", // Make the container take up the full height
            gap: "15px",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes} // Make sure to import faTimes from '@fortawesome/free-solid-svg-icons'
            onClick={() => setIsModalVisible(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              cursor: "pointer",
            }}
          />
          <button
            disabled={!artPieceInformation?.linkId}
            style={{
              backgroundColor: artPieceInformation?.linkId ? "#7800ff" : "grey",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontWeight: 700,
              fontSize: "16px",
              width: "150px",
              height: "45px",
            }}
            onClick={() => {
              console.log("this ran");
              unlinkArtPiece().then(() => {
                setIsModalVisible(false);
              });
            }}
          >
            Unlink
          </button>
          <button
            style={{
              backgroundColor: "#7800ff",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontWeight: 700,
              fontSize: "16px",
              width: "150px",
              height: "45px",
            }}
            onClick={() => {
              // Open up the QR code
              navigate(`/scanner`, {
                state: { artPieceInformation: artPieceInformation },
              });
              setIsModalVisible(false);
            }}
          >
            Link New
          </button>
          <button
            style={{
              backgroundColor: "#7800ff",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontWeight: 700,
              fontSize: "16px",
              width: "150px",
            }}
            onClick={createTag}
          >
            Generate Tag
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;
