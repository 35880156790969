import React, { useEffect, useState } from "react";
import art from "../assets/art.webp";
import Dropdown from "../components/Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Spin, Tooltip } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { timeStamp } from "console";
import { Comment } from "../types/types";
import ResponsiveImage from "../components/ResponsiveImage";
import FsLightbox from "fslightbox-react";
import {
  formatTimestamp,
  generateLatestLocation,
  getRandomColor,
  listLatestLocationForCustomerFacingPiece,
} from "../lib/utils";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Comments: React.FC = () => {
  const { linkId } = useParams();
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [artPieceInformation, setArtPieceInformation] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  const [selectedLocationName, setSelectedLocationName] =
    useState("All Comments");
  const [artistInformation, setArtistInformation] = useState<any>({});
  const navigate = useNavigate();

  const postComment = async (comment: string) => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/comment/${artPieceInformation._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text: comment, username: name }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to post comment");
      }
      const result = await response.json();
      if (result.locationHistory.length > 0) {
        // const latest = generateLatestLocation(result.locationHistory);
        const latest = listLatestLocationForCustomerFacingPiece(
          result.locationHistory
        );
        setArtPieceInformation({
          ...result,
          location: latest.location,
          comments: result.comments.map((comment: Comment) => {
            const colorPicked = getRandomColor();
            return {
              ...comment,
              color: colorPicked,
            };
          }),
        });
        setSelectedLocationId(latest.id);
        setSelectedLocationName(latest.location.name);
      } else {
        setArtPieceInformation(result);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const fetchArtistInformation = async () => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/artist/${artPieceInformation.artistUid}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch artist information");
      }
      const artist = await response.json();
      setArtistInformation(artist);
    } catch (error) {
      console.error("Error fetching artist information: ", error);
    }
  };

  const fetchLinkedImage = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/link/${linkId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.ok) {
        setIsLoading(false);
        setArtPieceInformation(null);
      }
      const result = await response.json();

      if (result.locationHistory.length > 0) {
        // const latest = generateLatestLocation(result.locationHistory);
        const latest = listLatestLocationForCustomerFacingPiece(
          result.locationHistory
        );
        setArtPieceInformation({
          ...result,
          location: latest.location,
          comments: result.comments.map((comment: Comment) => {
            const colorPicked = getRandomColor();
            return {
              ...comment,
              color: colorPicked,
            };
          }),
        });
        setSelectedLocationId(latest.id);
        setSelectedLocationName(latest.location.name);
      } else {
        setArtPieceInformation(result);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    if (linkId) {
      fetchLinkedImage();
    }
  }, [linkId]);

  useEffect(() => {
    if (artPieceInformation?.artistUid) {
      fetchArtistInformation();
    }
  }, [artPieceInformation?.artistUid]);

  const handleLocationChange = (locationId: number, locationName: string) => {
    setSelectedLocationId(locationId);
    setSelectedLocationName(locationName);
  };

  const filteredComments = artPieceInformation?.comments.filter(
    (comment: Comment) => {
      return (
        comment.locationId === selectedLocationId || selectedLocationId === 0
      );
    }
  );

  // TODO: make this accessible by other components. Its being DRY'd
  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  };

  const userNameStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "50%",
    alignSelf: "flex-start",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100vh", // Take up the full viewport height
        }}
      >
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 100, color: "#7800FF" }} spin />
          }
        />
      </div>
    );
  }

  return (
    <div
      data-testid="comments-page"
      style={{
        // backgroundColor: "black",
        color: "white",
        padding: "20px",
        margin: "10px", // Added margin around the entire component
        paddingTop: "40px",
      }}
    >
      <FontAwesomeIcon
        onClick={() => navigate(-1)}
        icon={faAngleLeft}
        size="2xl"
      />
      {/* Container to center the image */}
      <ResponsiveImage
        src={artPieceInformation?.photo}
        alt="example"
        // @ts-ignore
        onToggle={() => setToggler(!toggler)}
      />
      <FsLightbox toggler={toggler} sources={[artPieceInformation?.photo]} />

      {/* Text and Icon (left-aligned) */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between",
          width: "350px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            justifyContent: "center",
            width: "350px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {" "}
            <h2 id="title" style={{ margin: 0 }}>
              {artPieceInformation?.name}
            </h2>
            <h4
              id="artist"
              style={{ margin: 0, paddingLeft: 10, marginBottom: "10px" }}
            >
              {artistInformation.displayName}
            </h4>
            <h2 id="title" style={{ margin: 0 }}>
              Photograph
            </h2>
            <h4
              id="artist"
              style={{ margin: 0, paddingLeft: 10 }}
              hidden={
                !artPieceInformation?.height ||
                !artPieceInformation?.width ||
                !artPieceInformation.units
              }
            >
              {artPieceInformation?.height &&
                `${artPieceInformation?.height || ""} x ${
                  artPieceInformation?.width || ""
                } ${artPieceInformation?.units || ""}`}
            </h4>
            {artPieceInformation?.location && (
              <Dropdown
                isLoading={isLoading}
                artPieceInformation={artPieceInformation}
                onLocationChange={handleLocationChange}
                selectedLocationName={selectedLocationName}
                selectedLocationId={selectedLocationId}
              />
            )}
          </div>
        </div>
      </div>

      <br />
      <h4 style={{ margin: 0, paddingLeft: 10, marginBottom: 20 }}>
        Comments:
      </h4>
      {filteredComments?.map((comment: Comment) => {
        return (
          <>
            {/* <h4 style={{ margin: 0, paddingLeft: 10 }}>{comment.event}:</h4> */}
            <h4
              id="description"
              style={{ margin: 0, paddingLeft: 10, marginBottom: 20 }}
            >
              <Tooltip title={formatTimestamp(comment.timestamp)}>
                <Avatar
                  style={{
                    backgroundColor: comment.color,
                    marginRight: 10,
                  }}
                  icon={<UserOutlined />}
                />
              </Tooltip>
              {comment.username}: {comment.text}
            </h4>
          </>
        );
      })}
      {/* <h4 style={{ margin: 0, paddingLeft: 10 }}>Day of the Dead:</h4>

      <h4
        id="description"
        style={{ margin: 0, paddingLeft: 10, marginBottom: 20 }}
      >
        User 1: I love the black and white!
      </h4>

      <h4 style={{ margin: 0, paddingLeft: 10 }}>First Friday 3/26/23:</h4>

      <h4
        id="description"
        style={{ margin: 0, paddingLeft: 10, marginBottom: 20 }}
      >
        User 1: This reminds me of a stock photo!
      </h4>
      <h4 style={{ margin: 0, paddingLeft: 10 }}>
        Night at the Museum: 7 comments
      </h4>
      <h4
        id="description"
        style={{ margin: 0, paddingLeft: 10, marginBottom: 20 }}
      >
        User 1: Great composition!
      </h4> */}
      {/* TODO: Add event corresponding to person */}
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <input
          data-testid="name-input"
          value={name}
          type="text"
          placeholder="Your Name"
          style={userNameStyle}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          value={comment}
          type="text"
          placeholder="Leave your thoughts here!"
          style={inputStyle}
          onChange={(e) => setComment(e.target.value)}
        />
        <button
          data-testid="send-button"
          style={{
            fontWeight: "bold",
            width: "125px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            marginTop: "10px",
          }}
          onClick={() => {
            postComment(comment);
            setComment("");
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Comments;
