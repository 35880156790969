import { useNavigate } from "react-router-dom";
import ArtPieces from "./ArtPieces";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import StripeStatus from "./StripeStatus";

interface HomePageContentProps {
  user: any;
  artPieces: any;
  handlePiece: (id: string) => void;
}

const HomePageContent = ({
  user,
  artPieces,
  handlePiece,
}: HomePageContentProps) => {
  const [isStripeFullyOnboarded, setIsStripeFullyOnboarded] = useState(false);
  const [stripeAccountDetails, setStripeAccountDetails] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const getAccountDetails = async (accountId: string) => {
    const response = await fetch(
      `https://sparkplug-api.onrender.com/user/stripe/connected-account/${accountId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("response: ", response);
    const data = await response.json();
    setStripeAccountDetails(data);
    console.log("data: ", data);
    if (data.requirements.currently_due.length === 0) {
      setIsStripeFullyOnboarded(true);
    } else {
      setIsStripeFullyOnboarded(false);
    }
    setIsLoaded(true);
    return data;
  };

  useEffect(() => {
    console.log("user: ", user && user.stripeAccount?.id !== "");
    if (user && user.stripeAccount?.id !== "") {
      getAccountDetails(user.stripeAccount?.id);
    }
  }, [user]);

  console.log(stripeAccountDetails);
  console.log("isStripeFullyOnboarded: ", isStripeFullyOnboarded);
  console.log("isLoaded: ", isLoaded);
  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        margin: "20px",
        marginBottom: "-15px", // TODO: is this even right?
      }}
    >
      {(user.displayName || user.name) && (
        <h1>
          Hi, {user.displayName || user.name || ""} <br /> Welcome to ArtLink!
        </h1>
      )}
      <StripeStatus
        isLoaded={isLoaded}
        isStripeFullyOnboarded={isStripeFullyOnboarded}
        detailsSubmitted={stripeAccountDetails?.details_submitted}
      />
      {/* {isStripeFullyOnboarded && isLoaded ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CheckCircleOutlined
            style={{ color: "#66FF00", marginRight: "10px" }}
          />
          <h4 style={{ margin: 0 }}>Stripe account connected</h4>
        </div>
      ) : (
        <div
          style={{ display: isLoaded ? "flex" : "none", alignItems: "center" }}
          onClick={() => navigate("/stripe-onboarding")}
        >
          {stripeAccountDetails?.details_submitted ? (
            <MinusCircleOutlined
              style={{ color: "yellow", marginRight: "10px" }}
            />
          ) : (
            <ExclamationCircleOutlined
              style={{ color: "#EE4B2B", marginRight: "10px" }}
            />
          )}

          <h4 style={{ margin: 0 }}>
            {stripeAccountDetails?.details_submitted
              ? "Please resume account onboarding to start selling. Click here to continue."
              : "Complete your account information to start selling. Click here to start."}
          </h4>
        </div>
      )} */}
      {user.roleId === 2 && (
        <button
          style={{
            width: "125px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginTop: "20px",
          }}
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin Panel
        </button>
      )}

      <div style={{ textAlign: "left" }}>
        <h1 style={{ marginTop: "50px" }}>Your pieces</h1>
        <ArtPieces artPieces={artPieces} handlePiece={handlePiece} />
      </div>
    </div>
  );
};

export default HomePageContent;
