import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const cardElement = elements?.getElement(CardElement);

    // @ts-ignore
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      fetch("https://sparkplug-api.onrender.com/payment/create-charge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          source: paymentMethod.id,
          amount: 1000,
          artistStripeAccountId: "acct_1PIipfEEpCkBgG6x", // TODO: change this
        }),
      }).then((response) => {
        response.json().then((data) => {
          console.log(data);
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button className="checkout-button" type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
